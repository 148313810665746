/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:4366a971-d2fc-49e5-82f7-2b2cfe9df4cb",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_of7y5lKy1",
    "aws_user_pools_web_client_id": "6n7n3qpcf5589ecn4ne6rm52mo",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://fjudt8v0nl.execute-api.ca-central-1.amazonaws.com/whghprod",
            "region": "ca-central-1"
        },
        {
            "name": "saveledgerentry",
            "endpoint": "https://ygq5h4d6wh.execute-api.ca-central-1.amazonaws.com/whghprod",
            "region": "ca-central-1"
        }
    ],
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "ppetable-whghprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "locationinfo-whghprod",
            "region": "ca-central-1"
        },
        {
            "tableName": "ppeledger-whghprod",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "qwantifyppecartprofile160700-whghprod",
    "aws_user_files_s3_bucket_region": "ca-central-1",
    "aws_content_delivery_bucket": "qwppedev-whghprod",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d2fnjng2fy7xtd.cloudfront.net"
};


export default awsmobile;
