import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import TableProt from "./TableProt";
import TableForsave from "./TableForsave";
import React, {useEffect, useState} from "react";
import {API, Auth} from "aws-amplify";
import MenuItem from "@material-ui/core/MenuItem";
import TableReports from "./TableReports";
import RMaterialUIPickers from "./RMaterialUIPickers";
import Button from "@material-ui/core/Button";
import {CSVLink} from "react-csv";


export default function Reports( props ) {

    const [site, setSite] = useState('---');
    const [selectedDate, setSelectedDate] = useState(  );
    const [hospitalcode, setHospitalcode] = useState('') ;
    const [user, setUser] = useState('');
    const[satellites, setSatellites] = useState([]);
    const[cages, setCages] = useState([]) ;
    const[wards, setWards] = useState([]);
    const [ parentcode, setParentcode] = useState('');
    const[hospitals, setHospitals] = useState([]);
    const [satelliteSelect, setSatelliteSelect] = useState('---') ;
    const [cageselect, setCageselect] = useState('---');
    const [rows, setRows] = useState([]);
    const [myMap, setMyMap] = useState(new Map());
    const updateMap = (k,v) => {
        setMyMap( new Map( myMap.set(k,v) ) );
    }

    const [csvData, setCsvdata] = useState( [] );



    useEffect(() => {
        //getHospcode();
        getParentcodefromCognito();

    }, [hospitalcode] );


    async function getHospcode() {
        console.log('getHospcode');
        const user = await Auth.currentAuthenticatedUser();
        let authinfo = await Auth.currentUserInfo();
        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        setHospitalcode( favoriteFlavor );
    }

    async function getParentcodefromCognito() {
        console.log('getHospcode');
        const user1 = await Auth.currentAuthenticatedUser();
        setUser(user1);
        console.log('###user1', user1 );
        console.log('USER:', user);
        console.log('###user email', user.email );
        let authinfo = await Auth.currentUserInfo();


        const favoriteFlavor = authinfo.attributes['custom:healthsystem'] ;
        //setHospitalcode( favoriteFlavor );
        setParentcode(favoriteFlavor);
        console.log('parentcode from cognito', favoriteFlavor );
        await getlocationinfo(favoriteFlavor);
    }


    async function getlocationinfo(pccode) {

        console.log('##1- getlocationinfo-parentcode', pccode );

        let apiName = 'saveledgerentry';
        let path = '/getlocinfo';

        const myInit = { // OPTIONAL
            body: {
                pccode: pccode,
            },
            headers: {
                //'Content-Type' : 'application/json',
                // Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`
            }
        };

        console.log('2- getlocationinfo with parentcode', parentcode );
        //console.log(API.endpoint(apiName));

        let resp1 = await API.post(apiName, path, myInit );
        console.log(resp1);
        //setRows( [...resp1.body.Items] ) ;




        let optionItemsCage = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('CAGE#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsCage.push  ( <MenuItem key='---' value='---'>SELECT CAGE</MenuItem>)
        setCages(optionItemsCage);

        //wards
        let optionItemsWard = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('WARD#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsWard.push  ( <MenuItem key='---' value='---'>SELECT WARD</MenuItem>)
        setWards(optionItemsWard);

        //hosps
        let optionItemsHospital = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('HOSP#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsHospital.push  ( <MenuItem key='---' value='---'>SELECT HOSP</MenuItem>)
        setHospitals(optionItemsHospital);

        let optionItemsSat = resp1.body.Items.filter( function (e) {
            return e.pk2.startsWith('SAT#');
        }).map( (e2) =>
            <MenuItem key={e2.pk2} value={e2.pk2}>{e2.friendlyname}</MenuItem>
        );
        optionItemsSat.push ( <MenuItem key='---' value='---'>SELECT SAT</MenuItem>)
        setSatellites( optionItemsSat ) ;

    }

    function getQueryDateNowString() {


        let date1  ; //= new Date();
        if(selectedDate) {
            console.log('### SELECTED DATE##### -', selectedDate);
            date1 = new Date( selectedDate ) ;
            //let date2 = new Date( selectedDate );
            //date1.setDate( date2.getDate()-1  );
            console.log('### DATE CHANGED ##### -', date1 );
        }else{
            date1 = new Date();
            //date1.setDate( (new Date()).getDate()-1 );
        }

        console.log('*** date1-QDS', date1);

        console.log('*** date1', date1);

        let year = date1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( date1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
       // let day = date1.getUTCDate() ;

        let day = (date1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( date1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;

        console.log("###ZULU conversion", datestring );

        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function getQueryDateString() {


        let date1  ; //= new Date();
        if(selectedDate) {
            console.log('### SELECTED DATE##### -', selectedDate);
            date1 = new Date( selectedDate ) ;
            let date2 = new Date( selectedDate );
            date1.setDate( date2.getDate()-1  );
            console.log('### DATE CHANGED ##### -', date1 );
        }else{
            date1 = new Date();
            date1.setDate( (new Date()).getDate()-1 );
        }

        console.log('*** date1-QDS', date1);

        let year = date1.getUTCFullYear() ;
        //let month =  date1.getUTCMonth() +1 ;
        let month = ( date1.getUTCMonth() +1 ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let day = date1.getUTCDate() ;
        let day = (date1.getUTCDate() ).toLocaleString('en-US',{minimumIntegerDigits: 2, useGrouping:false})

        let hour = ( date1.getUTCHours()  ).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping:false})
        //let datestring = month + ":" + day +":" + year  ;
        let datestring = year + '-' + month + '-' + day + 'T' + hour;



        console.log("###ZULU conversion", datestring );

        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function getDateString() {

        // INVLEVELCAGEARCHIVE#PARENTCODE#C1008HOSPITALCODE#C1008CAGE#CAGE01DATE#7:21:2020
        //let date1 = new Date();
        let date1;
        if(selectedDate) {
             date1 = selectedDate;
        }else{
            date1 = new Date();
        }


        let year = date1.getUTCFullYear() ;
        let month =  date1.getUTCMonth() +1 ;
        let day = date1.getUTCDate() ;
        let datestring = month + ":" + day +":" + year  ;


        return datestring;
        //
        //let pk1 = pk1orig.replace(/INVLEVELCAGE/i, 'INVLEVELCAGEARCHIVE') + 'DATE#' + datestring;
        // let pk1 = 'INVLEVELCAGEARCHIVE#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode +
        //     'CAGE#';



    }

    function isdateToday(datestring) {

        let date1 = new Date();

        let year = date1.getUTCFullYear() ;
        let month =  date1.getUTCMonth() +1 ;
        let day = date1.getUTCDate() ;
        let datestringtoday = month + ":" + day +":" + year  ;

        if(datestringtoday === datestring){
            console.log('date string is today')
            return true;
        }
        else{
            console.log('date string is NOT today');
            return false;
        }

    }

    async function loadinvlevels (){

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getDateString();

        isdateToday(datestring);


        setRows([]);
        updateMap( myMap.clear() );

        let counter = 0;

        for (const r of cages ) {

            console.log('### R.KEY ', r.key );


            if( ( cageselect === '---' ) && ( r.key !='---')   || (cageselect === r.key) ) {
                console.log('r', r.key);

                console.log('### LOOPING...');

                let myInit;

                if( selectedDate && !isdateToday(datestring)) {

                     myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGEARCHIVE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key + 'DATE#' + datestring,
                            pk2: '',
                        },
                    };
                }
                else{
                    //                console.log('INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key);
                    myInit = {
                        'queryStringParameters': {
                            pk1: 'INVLEVELCAGE#PARENTCODE#' + hospitalcode + 'HOSPITALCODE#' + hospitalcode + r.key,
                            pk2: '',
                        },
                    };

                }

                console.log('searchtext', myInit );

                let resp1 = await API.get(apiName, path, myInit);

                console.log('@@#####response', resp1.body.Items ) ;

                if(resp1.body.Items.length > 0 ) {
                    //setRows(rows.concat([...resp1.body.Items]));

                    let rowstemp = [...resp1.body.Items] ;


                    let dataarray = [];
                    let arraycounter = 0;
                    //let fileheader = ['VENDORCATALOGNUMBER','QOH', 'DESCRIPTION' ] ;
                    //dataarray[0] = fileheader;
                    for (const r1 of rowstemp) {
                        let dataline = [];
                        r1.include = 1 ;
                        r1.rowindexer = counter++;
                        r1.pk2 = '---' ;

                        dataline[0]= r1.vendorcatalognumber;
                        dataline[1] = r1.quantity;
                        dataline[2] = r1.description.replace("\"",'');
                        dataline[3] = r1.unitofpurchase ;

                        dataarray[arraycounter] = dataline ;
                        //setCsvdata(csvData.concat(dataline) );
                        arraycounter++;
                        console.log('###DATALINE', dataline );
                        console.log('$$$DATARRAY', dataarray);
                        //llUsers = allUsers.concat ( [...rest.Users]  ) ;

                        //setCsvdata( dataline );

                        updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity )  : r1.quantity );

                    }

                    setRows(rows => rows.concat( rowstemp  ));
                    console.log("###DATA ARRAY LENGTH", dataarray.length);
                    setCsvdata( csvData => csvData.concat( dataarray ) );
                }

            }

        }

        //totals

        console.log('###myMap', myMap) ;



    }
//INVLEVELCAGEARCHIVE#PARENTCODE#C1008HOSPITALCODE#C1008CAGE#CAGE01DATE#7:21:2020
    const handleDatechange = (prop) => (val) =>  {
        console.log('handleDatechange - prop: ', prop );
        console.log('handleDatechange - val: ', val );
        setSelectedDate(val);
    }

    const handleChangeHospital = async ( val1  ) => {
        console.log('dropdown change with', val1 );
        console.log('dropdown change with', val1.target.value );
        setSite( val1.target.value );
        console.log('$$$$ 1 hospitalcode ', val1.target.value );
        let index1 = val1.target.value.indexOf('#');
        console.log('$$$$hospitalcode index', index1 );
        let hc = val1.target.value.substring(index1 +1 ) ;
        console.log('####hc', hc );
        setHospitalcode( hc  ) ;
        //console.log('$$$$hospitalcode', hospitalcode   ) ;
    };


    const handleChangeSatellite = (val3) => {
        console.log('###change###');
        setSatelliteSelect(val3.target.value);
        //setSelectsatellite(val3.target.value );
    };

    const handleChangecage = ( val ) => {
        setCageselect( val.target.value );
    }

    async function loadallactivity() {

        let counter = 0;

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getQueryDateString();
        let datenowstring = getQueryDateNowString() ;

        setRows([]);
        updateMap( myMap.clear() );


        let myInit = {
            'queryStringParameters': {
                pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                pk2: datestring,
                pk3: datenowstring,
            },
        };

        let resp1 = await API.get(apiName, path, myInit);

        console.log('@@#####response', resp1.body.Items ) ;

        setCsvdata([]);
        if(resp1.body.Items.length > 0 ) {
            //setRows(rows.concat([...resp1.body.Items]));

            let rowstemp = [...resp1.body.Items] ;

            let dataarray =[];
            let arraycounter = 0;
            for (const r1 of rowstemp) {

                let dataline = [];

                    r1.include = 1;
                    r1.rowindexer = counter++;
                    let locdate = new Date(r1.pk2);
                    console.log('#### ', locdate );
                    r1.pk2 = locdate.toString();

                dataline[0]= r1.vendorcatalognumber;
                dataline[1] = r1.quantity;
                dataline[2] = r1.description.replace("\"",'');

                dataarray[arraycounter] = dataline ;
                arraycounter++;

                    //updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity) : r1.quantity);

            }

            setRows(rows => rows.concat( rowstemp  )   );
            setCsvdata( csvData => csvData.concat( dataarray ) );

        }


    }
    async function loadtwentyfourhourconsumption() {

        setCsvdata([]);
        let counter = 0;

        console.log('loadinvlevels - selecteddate', selectedDate ) ;
        console.log('### cage select', cageselect );

        let apiName = 'saveledgerentry';
        let path = '/searchledgerbydate';

        let datestring = getQueryDateString();
        let datenowstring = getQueryDateNowString() ;

        setRows([]);
        updateMap( myMap.clear() );


        let myInit = {
                'queryStringParameters': {
                    pk1: 'INV#PARENTCODE#' + parentcode + 'HOSPITALCODE#' + hospitalcode,
                    pk2: datestring,
                    pk3: datenowstring,
                },
            };

        let resp1 = await API.get(apiName, path, myInit);

        console.log('@@#####response', resp1.body.Items ) ;

        if(resp1.body.Items.length > 0 ) {
            //setRows(rows.concat([...resp1.body.Items]));

            let rowstemp = [...resp1.body.Items] ;


            let dataarray = [];
            let arraycounter = 0;
            for (const r1 of rowstemp) {
                let dataline = [] ;

                if( r1.itemstatus ==='PICK') {

                    let dataline = [];
                    r1.include = 1;
                    r1.rowindexer = counter++;
                    let locdate = new Date(r1.pk2);
                    console.log('#### ', locdate );
                    r1.pk2 = locdate.toString();

                    dataline[0]= r1.vendorcatalognumber;
                    dataline[1] = r1.quantity;
                    dataline[2] = r1.description.replace("\"",'');

                    dataarray[arraycounter] = dataline ;
                    arraycounter++;

                    updateMap(r1.vendorcatalognumber, myMap.get(r1.vendorcatalognumber) ? (myMap.get(r1.vendorcatalognumber) + r1.quantity) : r1.quantity);

                }else{
                    console.log('#####NOT PICK') ;
                }

            }

            setRows(rows => rows.concat( rowstemp  ).filter( row => row.itemstatus === 'PICK')   );
            setCsvdata( csvData => csvData.concat( dataarray ) );
        }


    }

    return (
        <div  className="Reports" >

            <main className="content" id="MAIN_CONTENT">

                <div className="inputs" >
                    <Select className="siteselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={site}
                            onChange={handleChangeHospital  }
                    >{hospitals}
                    </Select>

                    <Select className="selectsatellite"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={satelliteSelect}
                            onChange={handleChangeSatellite}
                    >{satellites}


                    </Select>

                    <Select className="cageselect"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={cageselect}
                            onChange={handleChangecage}
                    >
                        {cages}
                    </Select>



                </div>

                <div className="pickercomponents">
                    <RMaterialUIPickers selectedDate={selectedDate} handleDatechange={handleDatechange} className="datepicker"></RMaterialUIPickers><button className="reportsearchbutton" onClick={ async () => {
                    console.log('QUERY QOH');
                    if(!hospitalcode){
                        alert('SELECT HOSPITAL');
                        return;
                    }
                    await loadinvlevels();


                }}>QUERY QOH</button>
                    <button className="reportsearchbutton" onClick={ async () => {
                        console.log('###### PAST 24HR CONSUMED');
                        if(!hospitalcode){
                            alert('SELECT HOSPITAL');
                            return;
                        }
                        await loadtwentyfourhourconsumption();


                    }}>24HR CONSUMED</button>
                    <button className="reportsearchbutton" onClick={ async () => {
                        console.log('###### PAST 24HR CONSUMED');
                        if(!hospitalcode){
                            alert('SELECT HOSPITAL');
                            return;
                        }
                        await loadallactivity();


                    }}>ALL ACTIVITY</button>
                </div>



                <div>
                    { 1===0 &&
                        <ul>
                            {
                                [...myMap.keys()].map(k => (
                                    <li key={k}>VendorCat {k} {myMap.get(k)} </li>
                                ))
                            }
                        </ul>
                    }
                </div>

                <div className="audittables">
                    <TableReports rows={rows} myMap = {myMap}    />
                </div>
                <div>
                    <CSVLink className="reportsearchlink" data={csvData}  filename={"ppe.csv"} >DOWNLOAD</CSVLink>
                </div>


            </main>

        </div>
    );


}
